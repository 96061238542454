import VueApexCharts from 'vue-apexcharts';
export default {
  props: {
    graphLabels: {
      required: true,
      type: Array
    },
    graphData: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      series: [{
        name: "Ghc",
        data: this.graphData
      }],
      chartOptions: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        colors: ['#F7921C'],
        stroke: {
          width: 1
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: '',
          align: 'left'
        },
        labels: this.graphLabels,
        xaxis: {
          // type: 'datetime',
        },
        yaxis: {
          opposite: false
        },
        legend: {
          horizontalAlign: 'left'
        }
      }
    };
  }
};