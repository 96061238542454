var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": "bar",
      "options": _vm.chartOptions,
      "series": _vm.series,
      "height": "350"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };